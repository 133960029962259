import React from "react"
import { css, keyframes } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { fontStyle, color, spacing, size } from "../defaults/styles/theme"
import { wrapper } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import PlayIcon from "../defaults/assets/svg/play.svg"
import MapIcon from "../defaults/assets/svg/map.svg"
import Clock from "../defaults/assets/svg/clock.svg"
import Flags from "../../data/raw/flags"
import Link from "../link"

const Join = () => {
  const Timings = ({ start, location, online }) => {
    const blink = keyframes`
      from {
        opacity: 1.0;
      }
      50% {
        opacity: .1;
      }
      to {
        opacity: 1.0;
      }
    `

    const TimingsInner = ({ start, location, online }) => {
      return (
        <div
          css={[
            css`
              background: ${Flags.features.live && online
                ? color.plue_rec
                : color.gray800};
              border-radius: 1em;
              padding: 4px;
              display: flex;
              align-items: center;
              margin-bottom: 1em;
              p {
                color: white;
                font-size: 0.8em;
                margin: 0;
                font-weight: 600;
                letter-spacing: 0.025em;
              }
            `,
            Flags.features.live && online
              ? css`
                  animation: ${blink} 2s ease-in-out infinite;
                `
              : null,
          ]}
        >
          <div
            css={css`
              background: ${Flags.features.live && online
                ? color.gray800
                : color.plue300};
              border-radius: 1em;
              padding: 0.25em 1em;
              display: flex;
            `}
          >
            <div
              css={[
                css`
                  height: 1em;
                  margin-right: 1em;
                  svg {
                    stroke: white;
                    max-height: 100%;
                    width: auto;
                  }
                `,
              ]}
            >
              <Clock />
            </div>
            <p>{start}</p>
          </div>
          <p
            css={css`
              padding: 0.25em 1em;
            `}
          >
            {location}
          </p>
        </div>
      )
    }
    return online && Flags.features.live ? (
      <Link
        to={Flags.settings.live.destination}
        css={css`
          text-decoration: none;
        `}
      >
        <TimingsInner start={start} location={location} online={online} />
      </Link>
    ) : (
      <TimingsInner start={start} location={location} online={online} />
    )
  }

  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            overflow-x: visible;
            display: flex;
            flex-direction: column-reverse;
            > * {
            }
          `,
        ]}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            justify-content: flex-end;
            ${mq[1]} {
              overflow: hidden;
              right: calc(60% + 4em);
              position: absolute;
            }
            ${mq[2]} {
              right: calc(50% + 4em);
            }
          `}
        >
          <StaticImage
            src="../../../static/img/20220615-videoplayer4.png"
            alt="Former EXPO speaker of STARTUP AUTOBAHN powered by Plug and Play"
            quality="100"
            placeholder="tracedSVG"
            css={css`
              min-width: 110%;
              ${mq[1]} {
                min-width: 900px;
              }
            `}
          />
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            ${mq[1]} {
              align-items: flex-end;
              text-align: right;
              margin-left: 40%;
              min-height: 529px;
            }
            ${mq[2]} {
              margin-left: 50%;
            }
          `}
        >
          <h2 css={fontStyle.header.landingSection}>
            Where everything comes{" "}
            <span
              css={css`
                background-image: linear-gradient(
                  135deg,
                  ${color.plue400} 0%,
                  ${color.plue100} 100%
                );
                background-clip: text;
                text-fill-color: transparent;
                background-repeat: no-repeat;
              `}
            >
              together
            </span>
          </h2>

          <Timings location="On-Site Event" start="12:30pm CEST" />
          <Timings location="Online-Broadcast" start="1:30pm CEST" online />
          <div
            css={[
              css`
                p {
                  ${fontStyle.copy.landing}
                }
              `,
            ]}
          >
            {/* <p>
              While we are back in person this time and finally able to host more
              than a thousand open-innovation enthusiasts right in Stuttgart — we
              surely don‘t forget about our global community.
            </p> */}
            <p>
              While we are finally able to be back in Stuttgart at our usual
              scale — we surely don‘t forget about our global community.
            </p>
            <p>
              EXPO2022 will be accessible the entire day through our broadcasted
              online stream and available on our well-known rearview section
              later in July.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Join
